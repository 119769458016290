html {
    /* due to negative margin on map, need to disable scrolling on html */
    overflow: hidden !important;
}

#root {
    display: flex;
    flex-direction: column;
}

.unregistered-navbar {
    display: flex;
    background-color: #9ec1f7;
    padding-left: var(--bs-gutter-x, .75rem);
}

.unregistered-navbar-brand {
    font-size: 1.25rem;
    text-decoration: none;
    color: rgba(0, 0, 0, .8);
}

.unregistered-navbar-brand:hover {
    color: rgba(0, 0, 0);
}

.unregistered-navbar-brand-on-map {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 1.25rem;
    text-decoration: none;
    color: rgba(0, 0, 0, .8);
    z-index: 1000;
}

.unregistered-navbar-brand-on-map:hover {
    color: rgba(0, 0, 0);
}

.landing-left-signup-button {
    width: 150px;
    height: 50px;
    border-radius: 5px;
    background: #f7c29e;
    color: rgba(34 34 34 / 0.9);
    border: 1px solid transparent;
    box-shadow: 1px 1px 5px rgb(118 118 118 / 80%);
}

.landing-right-image {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    border-radius: 5px;
}

.unregistered-div-hidden {
    display: none;
}

.landing-left {
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* background: rgba(158, 193, 247, .4); */
    /* position: absolute; */
    /* left: 25%;
    top: 50%;
    transform: translate(-50%, -50%); */
    user-select: none;
    /* text-shadow: 1px 1px 1px rgb(0, 0, 0, 0.5); */
    width: 50%;
}

.landing-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 10px;
    padding-bottom: 5px;
    padding-right: 5px;
}


/* all devices */

.landing-all {
    width: 100%;
    background-color: #9ec1f7;
    flex: 1;
}

.unregistered-div-show {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1001;
    padding-bottom: 23px;
}


/* mobile */

@media (min-width: 0px) and (max-width: 500px) {
    .unregistered-div-show {
        height: 100%;
        width: 100%;
    }
    .landing-all {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .landing-left {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .landing-left>* {
        white-space: nowrap;
    }
    .landing-right {
        width: 100%;
    }
    .unregistered-div-show>.registration-login-inner-div {
        position: relative;
    }
    .unregistered-div-show>.registration-login-inner-div>.registration-login-close {
        top: 2px;
        right: 2px;
    }
}


/* desktop */

@media (min-width: 500px) {
    .unregistered-div-show {
        width: 50%;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }
    .landing-all {
        display: flex;
        overflow: auto;
    }
    .landing-right {
        width: 50%;
    }
}

.registration-login-inner-div {
    width: 100%;
}

.registration-login>.nav {
    display: block;
}

.registration-login>.nav-tabs>li {
    float: none;
    display: inline-block;
}

.registration-login-close {
    position: absolute;
}

.nav-tabs {
    text-align: center;
}

.nav-tabs>li>button {
    color: #495057de;
}

.nav-tabs>li>button:hover {
    color: #495057;
}

.social-network-login {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
    text-align: center;
}

.social-network-login-icon {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.social-network-login-icon:hover {
    opacity: 0.8;
}

.login-with-google {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-height: 0px) and (max-height: 600px) {
    .registration-login {
        background-color: snow;
        padding: 10px;
    }
    .login-register-form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .login-register-form-grid-left {
        flex: 0 0 110px;
        white-space: nowrap;
        margin-bottom: 10px;
    }
    .login-register-form-grid-right {
        flex: 1 1 calc(100% - 110px);
        margin-bottom: 10px;
    }
    .login-register-error-message {
        color: #dc3545;
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (min-height: 600px) {
    .registration-login {
        background-color: snow;
        padding: 30px;
    }
    .login-register-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .login-register-form-grid-right {
        margin-bottom: 10px;
    }
    .login-register-error-message {
        color: #dc3545;
        margin-bottom: 15px;
    }
}

.unregistered-footer {
    position: absolute;
    bottom: 0px;
    height: 23px;
    width: 100%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    z-index: 1000;
}

.unregistered-footer-trademark {
    opacity: 0.6;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.unregistered-footer-link {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
}

.unregistered-footer-link>a {
    text-decoration: none;
    color: black;
    opacity: 0.6;
}

.unregistered-footer-link>a:hover {
    color: black;
    opacity: 1;
}

.other-main {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    overflow: auto;
    padding: 10px 30px 20px 10px;
}

@media (min-width: 0px) and (max-width: 500px) {
    .landing-googlemap {
        height: 125%;
        width: 100%;
        position: absolute;
        top: 0;
        background-color: #9ec1f7;
        margin-bottom: calc(-25%);
    }
    .unregistered-imageViewer-show {
        position: absolute;
        width: 100%;
        height: 40%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1001;
    }
    .unregistered-imageViewer-show>.image-viewer {
        padding-bottom: 0px !important;
    }
    .unregistered-imageViewer-show>.image-viewer>.image-viewer-close-button {
        /* on mobile, put close button on top right corner, with small space between screen edge */
        right: 2px;
        top: 4px;
        left: auto;
    }
}

@media (min-width: 500px) {
    .landing-googlemap {
        height: 100%;
        width: 125%;
        position: absolute;
        right: 0;
        background-color: #9ec1f7;
        margin-right: calc(-25%);
    }
    .unregistered-imageViewer-show {
        z-index: 1001;
        height: 80%;
        width: 50%;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
    }
    .unregistered-imageViewer-show>.image-viewer {
        padding-bottom: 0px !important;
        border-radius: 0.5rem;
        box-shadow: 3px 3px 5px 1px rgb(0 0 0 / 30%);
    }
}

.unregistered-form-button {
    background-color: #f7c29e;
    color: rgba(34 34 34 / 0.9);
    box-shadow: 0px 0px 4px rgb(189 172 172 / 50%);
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
}